import {
	chakra,
	Grid,
	GridItem,
	Link,
	LinkBox,
	LinkOverlay,
	useMultiStyleConfig,
	VisuallyHidden,
	VStack,
} from "@chakra-ui/react";
import React from "react";
import { GetInsuranceProductsByFamilyIdQuery } from "src/__generated__/cms-schema.codegen";
import { DatoAsset, DatoAssetData } from "src/components/Dato/DatoAsset";
import { HeadingLevelBoundary, Hx } from "src/components/Heading/Heading";
import { FormattedPriceDescription } from "src/components/Product/Product";
import { useTranslatedString } from "src/i18n/i18n";
import { ArrowRight } from "src/icons";
import { useRole, usePortalType } from "src/lib/hooks";
import { isHiddenProduct } from "src/lib/productAndTGFilters";
import { replacePipeWithShy } from "src/lib/replaceStringWithReactNode";
import { truthy } from "src/lib/utils";
import { useLoginInfo } from "src/queries/emil/account";
import { useGetProductsFromStructuredText } from "src/sections/ProductComparison/utils";
import { z } from "zod";
import { getFormattedProducts, Products, useGetProducts } from "./utils";
import { NextLink } from "../NextLink";
import { createProductIdAndHref } from "../ProductGroupPageContent/ProductGroupPageContent";
import {
	StructuredText,
	StructuredTextData,
} from "../StructuredText/StructuredText";
import {
	TopicSelector,
	useTopicSelector,
} from "../TopicSelector/TopicSelector";

export type ProductOverviewProps = {
	products?: GetInsuranceProductsByFamilyIdQuery["allInsuranceProducts"];
	structuredText?: StructuredTextData;
	hasTopicSelector?: boolean;
	topicSelectorText?: string | null;
	customOrder?: Array<{ name: string; productGroupName?: string }>;
	introAlignment: string;
};

type ProductOverviewItemProps = {
	id: string;
	name: string;
	href: string;
	image: DatoAssetData | null;
	currentVersion: GetInsuranceProductsByFamilyIdQuery["allInsuranceProducts"][number]["currentVersion"];
	description: string;
	hasBookingFunnel: boolean;
};

const ProductOverviewItem: React.FC<ProductOverviewItemProps> = ({
	id,
	name,
	href,
	image,
	currentVersion,
	description,
	hasBookingFunnel,
}) => {
	const t = useTranslatedString();

	const styles = useMultiStyleConfig("ProductOverview", {
		hasBookingFunnel,
	});

	const externalLink = z
		.string()
		.url()
		.min(1)
		.safeParse(currentVersion.externalLink);

	return (
		<LinkBox as={GridItem} sx={styles.gridItem}>
			{image && (
				<DatoAsset objectFit="cover" height="12.5rem" data={image} />
			)}
			<chakra.span __css={styles.productType}>
				{hasBookingFunnel
					? t("productOverview.localProduct")
					: t("productOverview.externalProduct")}
			</chakra.span>

			<chakra.div __css={styles.textWrapper}>
				<LinkOverlay
					href={externalLink.success ? externalLink.data : href}
				>
					<HeadingLevelBoundary>
						<Hx size="h4">{name}</Hx>
					</HeadingLevelBoundary>
				</LinkOverlay>
				<chakra.span>{description}</chakra.span>

				<chakra.div __css={styles.bottomWrapper}>
					{externalLink.success ? (
						<Link
							sx={styles.externalLink}
							href={externalLink.data}
							id={`click_product__${id}`}
							isExternal
						>
							<chakra.span sx={styles.externalLinkText}>
								{currentVersion.externalLinkText?.length
									? currentVersion.externalLinkText
									: replacePipeWithShy(name)}
							</chakra.span>
							<ArrowRight sx={styles.arrow} />
						</Link>
					) : (
						<>
							{currentVersion.price && (
								<chakra.div __css={styles.price}>
									<FormattedPriceDescription
										price={currentVersion.price}
										priceDescription={
											currentVersion.priceDescription ??
											undefined
										}
									/>
								</chakra.div>
							)}

							<NextLink href={`/${href}`} passHref>
								<Link
									id={`click_product__${id}`}
									sx={styles.link}
								>
									<VisuallyHidden>{name}</VisuallyHidden>
									<ArrowRight sx={styles.arrow} />
								</Link>
							</NextLink>
						</>
					)}
				</chakra.div>
			</chakra.div>
		</LinkBox>
	);
};

export const ProductOverview: React.FC<ProductOverviewProps> = ({
	products,
	structuredText,
	hasTopicSelector,
	topicSelectorText,
	introAlignment,
	customOrder,
}) => {
	const t = useTranslatedString();
	const styles = useMultiStyleConfig("ProductOverview", { introAlignment });
	const loginInfo = useLoginInfo();
	const rolePermission = useRole();
	const portal = usePortalType();

	const { intro, products: productsFromStrucText } =
		useGetProductsFromStructuredText(structuredText);

	// as these (productsFromStrucText) contain unique product slugs, we'll have to add all products with the same slug
	const { products: enrichedProducts } = useGetProducts(
		productsFromStrucText,
	);

	const { singleProducts, cheapestProducts } = getFormattedProducts(
		enrichedProducts ?? products ?? [],
	);

	const order = productsFromStrucText
		?.map(({ linkedProduct }) => {
			return linkedProduct
				? { name: linkedProduct.name.replaceAll("|", "") }
				: undefined;
		})
		.filter(truthy);

	const names = customOrder?.map(({ name }) => name.replaceAll("|", ""));
	const productGroupNames = customOrder?.map(
		({ productGroupName }) => productGroupName,
	);

	const allProducts = [...singleProducts, ...cheapestProducts].sort(
		(a, b) => {
			// helper function to get the index of a product's name or product group name
			const getIndex = (product: Products[number]) => {
				// if we get structured products from a section, we'll have to search them instead of customOrder
				if (order) {
					return order.findIndex(
						({ name }) => name === product.name.replaceAll("|", ""),
					);
				}

				const productGroupNameIndex = productGroupNames?.findIndex(
					(productGroupName) =>
						productGroupName === product.productGroupName,
				);

				return productGroupNameIndex !== undefined &&
					productGroupNameIndex !== -1
					? productGroupNameIndex
					: (names?.indexOf(product.name.replaceAll("|", "")) ?? -1);
			};

			const aIndex = getIndex(a);
			const bIndex = getIndex(b);

			// If product a's name or group name is found and product b's is not, place product a before product b
			if (aIndex !== -1 && bIndex === -1) return -1;
			// If product b's name or group name is found and product a's is not, place product b before product a
			if (bIndex !== -1 && aIndex === -1) return 1;
			// If both product a and b's names or group names are found, sort them based on their index
			if (aIndex !== -1 && bIndex !== -1) return aIndex - bIndex;

			// If neither product a nor b's names or group names are found, don't change their order (they'll be sorted by last updated)
			return 0;
		},
	);

	const productFamilies = allProducts.map(
		({ productFamily }) => productFamily,
	);

	const { allTopics, currentTopic, setCurrentTopic } = useTopicSelector(
		productFamilies.map(({ name, slug, color: { hex } }) => ({
			name,
			slug,
			color: hex,
		})),
	);

	const productsFiltered = allProducts.filter((b) =>
		currentTopic === t("blogpost.allTopics.slug")
			? b
			: b.productFamily.slug === currentTopic,
	);

	const familyName = allProducts.find(
		(p) => p.productFamily.slug === currentTopic,
	)?.productFamily.name;

	return (
		<chakra.div backgroundColor="brand.lighter" padding="2rem">
			<VStack maxWidth="container.xl" gap={12} margin="auto">
				{intro && (
					<chakra.div __css={styles.intro}>
						<StructuredText data={intro} />
					</chakra.div>
				)}
				{allTopics.length > 2 && Boolean(hasTopicSelector) && (
					<TopicSelector
						topicSelectorText={
							topicSelectorText ?? t("blogpost.chooseTopic")
						}
						currentTopic={currentTopic}
						setCurrentTopic={setCurrentTopic}
						allTopics={allTopics}
					/>
				)}
				<Grid
					sx={styles.grid}
					templateColumns={{
						base: "repeat(1, 1fr)",
						md: "repeat(2, 1fr)",
						xl: "repeat(3, 1fr)",
					}}
				>
					{productsFiltered
						.filter(
							(product) =>
								!isHiddenProduct(
									product.slug,
									loginInfo,
									rolePermission,
									portal,
								),
						)
						.map((product) => {
							const { id, href } = createProductIdAndHref(
								product.slug,
								product.productFamily.slug,
								product.targetGroup?.slug,
								product.hasGroup,
							);

							return (
								<ProductOverviewItem
									key={id}
									{...product}
									name={product.name}
									description={
										product.currentVersion
											.productDescription
									}
									id={id}
									href={href}
									hasBookingFunnel={Boolean(
										product.showTariffCalculator,
									)}
								/>
							);
						})}
				</Grid>
				{hasTopicSelector && familyName && (
					<NextLink href={`/${currentTopic}`} passHref>
						<Link
							id={`click_see_all_${currentTopic}_products`}
							variant="outline"
						>
							{t("productOverview.toFamilyPage", {
								familyName,
							})}
						</Link>
					</NextLink>
				)}
			</VStack>
		</chakra.div>
	);
};

// 🔬 TBD
